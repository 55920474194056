import { defineStore } from 'pinia'
import { ref } from 'vue'
import {
  getAllCarts,
  getCartById,
  createNewCart,
  addToCart,
  removeItemByIndex
} from '@/services/lugand-api-services/cartService'
import type { FullCart } from '@/types/cart'
import { addAlert } from '@/services/lugand-api-services/alertService'
import { getFirestore, doc, deleteDoc } from 'firebase/firestore'

const db = getFirestore()

export const useCartStore = defineStore('cart', () => {
  const carts = ref<FullCart[]>([])
  const selectedCart = ref<FullCart | null>(null)

  const fetchCarts = async () => {
    try {
      const fetchedCarts = await getAllCarts()
      carts.value = fetchedCarts
    } catch (error) {
      addAlert('Error', 'Failed to fetch carts', 'error')
      console.error('Erro ao buscar carrinhos:', error)
    }
  }

  const fetchCartById = async (id: string): Promise<FullCart | null> => {
    try {
      const cart = await getCartById(id)
      selectedCart.value = cart // Armazena o carrinho selecionado
      return cart // Retorna o carrinho para quem chamar a função
    } catch (error) {
      addAlert('Error', 'Failed to fetch cart by ID', 'error')
      console.error('Erro ao buscar carrinho por ID:', error)
      return null // Retorna null em caso de erro
    }
  }

  const createCart = async (cartName: string): Promise<string> => {
    try {
      const newCartId = await createNewCart(cartName)
      await fetchCarts()
      addAlert('Success', 'Cart created successfully!', 'success')
      return newCartId
    } catch (error) {
      addAlert('Error', 'Failed to create cart', 'error')
      console.error('Erro ao criar novo carrinho:', error)
      throw error
    }
  }

  const addItemToCart = async (cartId: string, productId: number, quantity: number) => {
    try {
      await addToCart(cartId, productId, quantity)
      await fetchCarts()
      addAlert('Success', 'Item added to cart!', 'success')
    } catch (error) {
      addAlert('Error', 'Failed to add item to cart', 'error')
      console.error('Erro ao adicionar produto ao carrinho:', error)
    }
  }

  const removeItemFromCart = async (cartId: string, index: number): Promise<void> => {
    try {
      await removeItemByIndex(cartId, index) // Usa a função para remover via índice
      await fetchCarts() // Atualiza os carrinhos após a remoção
      addAlert('Success', 'Item removed from cart!', 'success')
    } catch (error) {
      addAlert('Error', 'Failed to remove item from cart', 'error')
      console.error('Erro ao remover produto do carrinho:', error)
    }
  }

  // Função para excluir um carrinho inteiro por ID
  const removeCart = async (cartId: string): Promise<void> => {
    const cartRef = doc(db, 'carts', cartId)
    await deleteDoc(cartRef)
    // Após deletar no Firestore, atualiza o estado de carts localmente
    carts.value = carts.value.filter((cart) => cart.id !== cartId)
    addAlert('Success', 'Cart removed successfully!', 'success')
  }

  return {
    carts,
    selectedCart,
    fetchCarts,
    fetchCartById,
    createCart,
    addItemToCart,
    removeItemFromCart,
    removeCart
  }
})
