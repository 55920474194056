import { defineStore } from 'pinia'
import { ref, onMounted } from 'vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore'
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject
} from 'firebase/storage' // Para lidar com o upload de imagem no Firebase Storage
import type { FullUser, UserData, AuthUser } from '@/types/user'

export const useUserStore = defineStore('user', () => {
  const user = ref<FullUser | null>(null)

  const db = getFirestore()
  const auth = getAuth()
  const storage = getStorage()

  // Função para carregar os dados do Firestore
  const loadUserFromFirestore = async (uid: string) => {
    const userRef = doc(db, 'users', uid)
    const userSnap = await getDoc(userRef)

    if (userSnap.exists() && user.value) {
      const data = userSnap.data() as UserData
      user.value = {
        ...user.value,
        ...data // Atualiza os dados do Firestore no usuário atual
      }
      localStorage.setItem('user', JSON.stringify(user.value)) // Salva no localStorage
    }
  }

  // Função para configurar apenas dados de autenticação (nome, email, photoURL)
  const setAuthUser = (authUser: AuthUser) => {
    if (user.value) {
      Object.assign(user.value, authUser)
    } else {
      user.value = {
        ...authUser,
        nick_name: null,
        avatar_image: null,
        first_name: null,
        last_name: null,
        CPF: null,
        data_nascimento: null,
        phones: null,
        addresses: null,
        companies: null
      }
    }
    localStorage.setItem('user', JSON.stringify(user.value))
  }

  // Função para fazer upload da imagem de avatar no Firebase Storage e obter a URL
  const uploadAvatar = async (file: File): Promise<string | null> => {
    if (user.value) {
      const userId = auth.currentUser?.uid
      if (!userId) return null

      // AVATAR IMAGE - Extraindo a extensão do arquivo
      const fileExtension = file.name.split('.').pop()
      // Nome do arquivo único (preservando a extensão)
      const avatarRef = storageRef(storage, `users/avatars/${userId}/avatar_image.${fileExtension}`)

      // Excluir o avatar anterior (se existir)
      try {
        await deleteObject(avatarRef)
      } catch (error) {
        console.warn('Nenhum avatar anterior encontrado ou erro ao deletar:', error)
        // Continua mesmo se não houver avatar anterior
      }

      // Fazer upload do novo avatar
      await uploadBytes(avatarRef, file)

      return await getDownloadURL(avatarRef) // Retorna a URL da imagem
    }
    return null
  }

  // Função para configurar ou atualizar dados complementares (como nick_name e avatar_image) no Firestore
  const setUser = async (userData: Partial<UserData>) => {
    if (user.value) {
      // Atualiza os valores localmente
      Object.assign(user.value, userData)

      // Atualiza o Firestore com os novos campos
      const userRef = doc(db, 'users', auth.currentUser?.uid!)
      await updateDoc(userRef, userData)
      // Armazena os dados atualizados no localStorage
      localStorage.setItem('user', JSON.stringify(user.value))
    }
  }

  const clearUser = () => {
    user.value = null
    localStorage.removeItem('user')
  }

  // Monitorar o estado da autenticação do Firebase Auth
  onMounted(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        const { displayName, photoURL, email, uid } = firebaseUser

        // Configura apenas os dados de autenticação
        setAuthUser({
          name: displayName ?? 'Unknown',
          photoURL: photoURL ?? '',
          email: email ?? ''
        })

        // Carrega o `nick_name` e `avatar_image` do Firestore separadamente
        loadUserFromFirestore(uid)
      } else {
        clearUser()
      }
    })
  })

  return { user, setAuthUser, setUser, uploadAvatar, clearUser, loadUserFromFirestore }
})
