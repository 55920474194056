<template>
  <div v-if="selectedCart" class="container">
    <div class="cart-info">
      <h2>{{ selectedCart.name }}</h2>
      <strong
        >Total <br />
        {{ calculateCartTotal() }}
      </strong>
      <button v-if="selectedCart && selectedCart.items.length > 0" @click="goToCheckout">
        Checkout
      </button>
    </div>

    <div class="item-list">
      <h3>Cart Items</h3>
      <!-- Verifica se o carrinho está vazio -->
      <div v-if="selectedCart.items.length === 0">
        <p>Your cart is empty.</p>
        <button @click="deleteCart(selectedCart.id)">Delete Cart</button>
      </div>

      <div v-else>
        <div class="cart-item">
          <div
            v-for="(item, index) in selectedCart.items"
            :key="item.product.id"
            class="cart-product"
          >
            <CartItem :item="item" :cartId="selectedCart.id" :index="index" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <p>Cart not found.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useCartStore } from '@/stores/lugand-api/cartStore' // Atualizado para usar Firestore
import { useCheckoutStore } from '@/stores/lugand-api/checkoutStore'
import CartItem from '@/components/CartItem.vue'

export default defineComponent({
  name: 'CartDetails',
  components: { CartItem },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const cartStore = useCartStore()

    const selectedCart = computed(() => cartStore.selectedCart)

    const goToCheckout = () => {
      const checkoutStore = useCheckoutStore()

      if (selectedCart.value) {
        checkoutStore.setItems(selectedCart.value.items)
        router.push({ name: 'checkout-view' })
      }
    }

    watch(
      () => route.params.id,
      (newId) => {
        if (newId) {
          cartStore.fetchCartById(String(newId)) // Alterado para string (FireStore UID)
        }
      },
      { immediate: true }
    )

    const calculateCartTotal = () => {
      if (!selectedCart.value) return 'R$0.00'

      return selectedCart.value.items
        .reduce(
          (total, item) => total + Number(item.product.price.valor_a_vista) * item.quantity,
          0
        )
        .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }

    // Função para deletar o carrinho se ele estiver vazio
    const deleteCart = async (cartId: string) => {
      try {
        await cartStore.removeCart(cartId)
        console.log('Cart deleted successfully!')
      } catch (error) {
        console.error('Error deleting cart:', error)
      }
    }

    return {
      selectedCart,
      calculateCartTotal,
      deleteCart,
      goToCheckout
    }
  }
})
</script>

<style scoped>
.container {
  width: 100%;
}

.cart-item {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
}

.cart-product {
  box-sizing: border-box;
  width: 100%;
}

.cart-info {
  font-size: 18px;
}

@media (min-width: 420px) {
  .cart-product {
    width: 250px;
  }
}
</style>
