<script setup lang="ts">
import { ref, onMounted } from 'vue'
import ProductCard from '../components/ProductCard.vue'
import ProductCarousel from '../components/ProductCarousel.vue'
import BannerCard from '../components/BannerCard.vue'
import BannerCarousel from '../components/BannerCarousel.vue'
import CategoryCarousel from '../components/CategoryCarousel.vue'
import Header from '../components/Header.vue'
import { type Banner, getAllBanners } from '../services/development/productService'
import {
  getAllProducts,
  getAllProductCategories
} from '../services/lugand-api-services/productService'
import type { Product, Category } from '@/types/product'

const products = ref<Product[]>([])
const banners = ref<Banner[]>([])
const productCategories = ref<Category[]>([])

onMounted(async () => {
  products.value = await getAllProducts()
  banners.value = await getAllBanners()
  productCategories.value = await getAllProductCategories()
})
</script>

<template>
  <div>
    <Header />
    <div class="container">
      <br />
      <h2>Best Products</h2>
      <ProductCarousel :cards="products">
        <template #default="{ card }">
          <ProductCard :product="card" />
        </template>
      </ProductCarousel>
      <h2>Banners</h2>
      <BannerCarousel :slides="banners">
        <template v-slot:default="{ slide }: { slide: Banner }">
          <BannerCard :image="slide.image" :title="slide.title" :description="slide.description" />
        </template>
      </BannerCarousel>
      <h2>Product Categories</h2>
      <div>
        <CategoryCarousel :categories="productCategories" />
      </div>
    </div>
  </div>
  <br />
</template>
