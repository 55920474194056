<script lang="ts">
import { ref, defineComponent, watch, onMounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'
import CartModal from '@/components/CartModal.vue'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { getTableData } from '@/services/development/productService'
import {
  getProductById,
  getProductImagesById,
  getAllProducts
} from '@/services/lugand-api-services/productService'
import type { Product } from '@/types/product'
import GenericTable from '@/components/GenericTable.vue'
import ProductCard from '@/components/ProductCard.vue'
import ProductCarousel from '@/components/ProductCarousel.vue'
import { useUserStore } from '@/stores/lugand-api/userStore' // Importa a store do usuário
import { useCheckoutStore } from '@/stores/lugand-api/checkoutStore'

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    GenericTable,
    ProductCard,
    ProductCarousel,
    CartModal
  },
  setup() {
    const modules = [FreeMode, Navigation, Thumbs]
    const route = useRoute()
    const router = useRouter()
    const product = ref<Product | null>(null)
    const relatedProduct = ref<Product[]>([])
    const thumbsSwiper = ref<any>(null)
    const tableData = ref<Record<string, any>[]>([])
    const loading = ref(false)
    const showModal = ref(false)
    const quantity = ref<number>(1)
    const userStore = useUserStore()

    const fetchTableData = async () => {
      tableData.value = await getTableData()
    }

    const fetchProductDetails = async (id: number) => {
      loading.value = true
      product.value = null
      destroySwipers() // Ensure old Swiper instances are destroyed

      try {
        const productData = await getProductById(id)
        const productImages = await getProductImagesById(id)

        if (productData) {
          productData.images = productImages
          product.value = productData
        }

        // Use nextTick to wait for the DOM to be updated, then add a delay
        await nextTick()
        // Se o swiper ainda não foi inicializado
        if (!thumbsSwiper.value || !thumbsSwiper.value.initialized) {
          console.log('Swiper instance initialized successfully.')
        }
      } catch (error) {
        console.error('Error fetching product details:', error)
      } finally {
        loading.value = false
      }
    }

    const goToPurshe = () => {
      const checkoutStore = useCheckoutStore()
      if (product.value && quantity.value > 0) {
        checkoutStore.setItems([{ product: product.value, quantity: quantity.value }])
        router.push({ name: 'checkout-view' })
      }
    }

    // Função chamada quando o botão "+Add to Cart" é clicado
    const handleAddToCart = () => {
      if (userStore.user) {
        // Usuário está logado, exibe a modal de confirmação de adição ao carrinho
        showModal.value = true
      } else {
        // Usuário não está logado, redireciona para a página de login
        router.push({ name: 'login' })
      }
    }

    const closeModal = () => {
      showModal.value = false // Fecha o modal
    }

    const destroySwipers = () => {
      if (thumbsSwiper.value) {
        if (thumbsSwiper.value.destroy) {
          thumbsSwiper.value.destroy(true, true)
        }
        thumbsSwiper.value = null
      }
    }

    const setThumbsSwiper = (swiper: any) => {
      try {
        thumbsSwiper.value = swiper
        console.log('Swiper instance has been initialized.')
      } catch (error) {
        console.error('Swiper initialization error:', error)
      }
    }

    watch(
      () => route.params.id,
      async (newId) => {
        if (newId) {
          // Convert `newId` to a number
          const productId = Number(newId)

          // Check if `productId` is a valid number
          if (!isNaN(productId)) {
            await fetchProductDetails(productId)
            console.log('product ID:', newId)
          } else {
            console.error('Invalid product ID:', newId)
          }
        }
      },
      { immediate: true }
    )

    onMounted(async () => {
      await fetchTableData()
      relatedProduct.value = await getAllProducts()
    })

    return {
      product,
      relatedProduct,
      thumbsSwiper,
      modules,
      tableData,
      setThumbsSwiper,
      goToPurshe,
      loading,
      handleAddToCart,
      closeModal,
      showModal,
      quantity
    }
  }
})
</script>

<template>
  <CartModal
    v-if="showModal && product"
    :productId="product?.id"
    :quantity="quantity"
    :showModal="showModal"
    :onClose="closeModal"
  />
  <br />
  <div v-if="loading">
    <p>Loading product details...</p>
  </div>
  <div v-else-if="product" class="product-details">
    <div class="product-image">
      <swiper
        :key="`swiper-${product.id}`"
        :style="{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff'
        }"
        :loop="true"
        :spaceBetween="10"
        :navigation="true"
        :thumbs="{ swiper: thumbsSwiper }"
        :modules="modules"
        class="mySwiper2"
      >
        <swiper-slide v-for="image in product.images" :key="image.id">
          <img :src="image.image" :alt="product.name" />
        </swiper-slide>
      </swiper>
      <swiper
        @swiper="setThumbsSwiper"
        :key="`thumbs-swiper-${product.id}`"
        :loop="true"
        :spaceBetween="10"
        :slidesPerView="4"
        :freeMode="true"
        :watchSlidesProgress="true"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="image in product.images" :key="image.id">
          <img :src="image.image" :alt="product.name" />
        </swiper-slide>
      </swiper>
    </div>
    <div class="product-info border">
      <h2>{{ product.name }}</h2>
      <h1>R${{ product.price.valor_a_vista }}</h1>
      <p>Stock: {{ product.stock_quantity }}</p>
      <br />
      <label for="quantity">Quantity: </label>
      <input type="number" v-model="quantity" id="quantity" name="quantity" min="1" value="1" />
      <div id="product-buttons">
        <br />
        <button id="pursche-button" @click="goToPurshe">Pursche</button>
        <button id="add-to-cart-button" @click="handleAddToCart">+ Add to Cart</button>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Loading product details...</p>
  </div>
  <div v-if="product" class="product-append">
    <br />
    <div>
      <h2>Related Products</h2>
      <ProductCarousel :cards="relatedProduct">
        <template #default="{ card }">
          <ProductCard :product="card" />
        </template>
      </ProductCarousel>
    </div>
    <br />
    <div>
      <h2>Product Properties</h2>
      <div>
        <div class="tables-container">
          <div class="table-section">
            <h3>Main Properties</h3>
            <GenericTable :data="tableData" />
          </div>
          <div class="table-section">
            <h3>Other Properties</h3>
            <GenericTable :data="tableData" />
          </div>
        </div>
      </div>
    </div>
    <br />
    <div>
      <h2>Product Description</h2>
      <p>{{ product.description }}</p>
    </div>
  </div>
  <div v-else></div>
</template>

<style scoped>
button {
  cursor: pointer;
}

input {
  height: 25px;
  width: 65px;
}

#product-buttons button {
  width: 100%;
  padding: 20px;
  margin-top: 10px;
}

#pursche-button {
  color: white;
  background-color: green;
}

#add-to-cart-button {
  color: white;
  background-color: blue;
}

.product-details {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.product-image {
  width: 50%;
  padding-right: 10px;
}

.mySwiper2 img,
.mySwiper img {
  width: 100%;
  height: auto;
}

.product-info {
  width: 50%;
  padding: 10px;
}

.tables-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 15px;
}

.table-section {
  flex: 1;
}

@media (max-width: 768px) {
  .product-details {
    display: block;
    padding: 0px;
  }

  .product-image {
    width: 100%;
    padding-right: 0;
  }

  .product-info {
    width: 100%;
    padding: 10px 0;
  }

  .tables-container {
    flex-direction: column;
  }

  .table-section {
    margin-bottom: 20px;
  }
}
</style>
