import axios from 'axios'
import { auth } from '@/firebase/index'
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
  signOut
} from 'firebase/auth'
import { useUserStore } from '@/stores/lugand-api/userStore'
import Cookies from 'js-cookie'

// Configuração do cliente Axios para a API DRF
const apiClient = axios.create({
  baseURL: 'http://localhost:8000/api',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

// Interceptor para adicionar o JWT em todas as requisicoes
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken') ?? Cookies.get('accessToken')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    const rejectionReason = error instanceof Error ? error : new Error(String(error))
    return Promise.reject(rejectionReason)
  }
)

// Função para registrar usuário na API DRF
const registerUserInAPI = async (email: string, password: string, additionalData: object) => {
  const response = await apiClient.post(`/register/`, {
    email,
    password,
    additional_data: additionalData
  })
  return response.data
}

// Funcao para armazenar tokens JWT
const storeTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem('accessToken', accessToken)
  localStorage.setItem('refreshToken', refreshToken)
}

// Funcao para remover tokens JWT
const clearTokens = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
}

// Registro direto pelo site
export const register = async (email: string, password: string) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    await sendEmailVerification(userCredential.user)

    // Registrar usuário na API DRF
    const { access, refresh } = await registerUserInAPI(email, password, {
      display_name: userCredential.user.displayName ?? '',
      nick_name: '' // Inicializa nick_name como vazio
    })

    // Armazenar tokens JWT
    storeTokens(access, refresh)

    return userCredential.user
  } catch (error) {
    console.error('Registration failed:', error)
    throw error
  }
}

// Login direto pelo site
export const login = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    if (!userCredential.user.emailVerified) {
      throw new Error('Email not verified')
    }

    // Configurar os dados de autenticação no userStore
    const userStore = useUserStore()
    userStore.setAuthUser({
      name: userCredential.user.displayName ?? 'Unknown',
      photoURL: userCredential.user.photoURL ?? '',
      email: userCredential.user.email ?? ''
    })

    return userCredential.user
  } catch (error) {
    console.error('Login failed:', error)
    throw error
  }
}

// Reset de senha direto pelo site
export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    console.log('Password reset email sent')
  } catch (error) {
    console.error('Password reset failed:', error)
    throw error
  }
}

// Função para login com Google
export const loginWithGoogle = async () => {
  const provider = new GoogleAuthProvider()
  const userStore = useUserStore()

  try {
    const result = await signInWithPopup(auth, provider)
    const user = result.user

    // Configura os dados básicos de autenticação no userStore
    userStore.setAuthUser({
      name: user.displayName ?? '',
      photoURL: user.photoURL ?? '',
      email: user.email ?? ''
    })

    // Simular que o usuário não é novo (temporariamente)
    const isNewUser = false // Você pode ajustar isso conforme necessário

    if (isNewUser) {
      userStore.setUser({
        nick_name: null // Apenas configurando dados básicos
      })

      return { user, isNewUser: true }
    } else {
      return { user, isNewUser: false }
    }
  } catch (error) {
    console.error('Google login failed:', error)
    throw error
  }
}

// Logout geral
export const logout = async () => {
  const userStore = useUserStore()
  try {
    // lout no firebase
    await signOut(auth)
    // limpa estado local do usuário
    userStore.clearUser()
    // Limpar tokens JWT
    clearTokens()
    console.log('Logout successful from both Firebase and Django')
  } catch (error) {
    console.error('Logout failed:', error)
    throw error
  }
}

export { auth }
