<template>
  <div class="fancy-title">
    <h1>My Account</h1>
    <hr />
  </div>
  <div class="user-account">
    <div v-if="user">
      <h2>Account Info</h2>
      <div class="user-account-section">
        <!-- Campo de e-mail imutável -->
        <div class="user-info">
          <label for="email">Account Email:</label>
          <p>{{ user.email }}</p>
        </div>

        <!-- Campo display_name imutável -->
        <div class="user-info">
          <label for="name">Account Display Name:</label>
          <p>{{ user.name }}</p>
        </div>
      </div>
      <br />
      <h2>Profile</h2>
      <div class="user-account-section">
        <!-- Campo nick_name editável -->
        <div class="user-info">
          <label for="nickname">Nickname:</label>
          <input v-model="updatedNickName" placeholder="Your nickname" />
        </div>

        <!-- Upload de imagem de avatar -->
        <div class="user-info">
          <label for="avatar">Avatar Image:</label>
          <input type="file" @change="onAvatarChange" />
          <div v-if="avatarURL">
            <p>Preview:</p>
            <img :src="avatarURL" alt="Avatar Preview" class="avatar-preview" />
          </div>
        </div>
      </div>
      <br />
      <h2>User Info</h2>
      <div class="user-account-section">
        <!-- Campo first_name -->
        <div class="user-info">
          <label for="first_name">First Name:</label>
          <input v-model="updatedFirstName" placeholder="Your first name" />
        </div>

        <!-- Campo last_name -->
        <div class="user-info">
          <label for="last_name">Last Name:</label>
          <input v-model="updatedLastName" placeholder="Your last name" />
        </div>

        <!-- Campo CPF -->
        <div class="user-info">
          <label for="CPF">CPF:</label>
          <input v-model="updatedCPF" placeholder="Your CPF" />
        </div>

        <!-- Campo data_nascimento -->
        <div class="user-info">
          <label for="data_nascimento">Date of Birth:</label>
          <input v-model="updatedDataNascimento" type="date" placeholder="Your date of birth" />
        </div>
      </div>
      <br />
      <h2>Phones</h2>
      <div class="user-account-section">
        <!-- Loop pelos telefones -->
        <div v-for="(phone, index) in updatedPhones" :key="index" class="user-info">
          <label for="phone-label">Descrição</label>
          <input v-model="phone.label" placeholder="Descrição (casa, trabalho)" />
          <label for="phone-number">Phone</label>
          <input v-model="phone.number" placeholder="Phone number" />
          <button class="remove-button" @click="removePhone(index)">Remove</button>
        </div>
        <!-- Botão para adicionar novo telefone -->
        <button class="add-button" @click="addPhone">+ Add Phone</button>
      </div>
      <br />
      <h2>Addresses</h2>
      <div class="user-account-section">
        <!-- Usando o AddressForm para cada endereço -->
        <div v-for="(address, index) in updatedAddresses" :key="index" class="user-info">
          <AddressForm
            :label="'Address ' + (index + 1)"
            :initialAddress="address"
            @save="updateAddress(index, $event)"
          />
          <button class="remove-button" @click="removeAddress(index)">Remove</button>
        </div>
        <button class="add-button" @click="addAddress">+ Add Address</button>
      </div>
      <br />
      <h2>Companies</h2>
      <div class="user-account-section">
        <!-- Loop pelas empresas -->
        <div v-for="(company, index) in updatedCompanies" :key="index" class="user-info">
          <label for="estado">Nome da Empresa</label>
          <input v-model="company.company_name" placeholder="Nome da Empresa" />
          <label for="CNPJ">CNPJ</label>
          <input v-model="company.cnpj" placeholder="CNPJ" />
          <label for="state_registration">Inscrição Estadual</label>
          <input v-model="company.state_registration" placeholder="Inscrição Estadual" />
          <label for="legal_representative">Representante Legal</label>
          <input v-model="company.legal_representative" placeholder="Representante Legal" />
          <button class="remove-button" @click="removeCompany(index)">Remove</button>
        </div>
        <!-- Botão para adicionar nova empresa -->
        <button class="add-button" @click="addCompany">+ Add Company</button>
      </div>
      <br />
      <button id="update-info-button" @click="updateUserInfo">Update and Save My Account</button>
    </div>
    <div v-else>
      <p>Loading user information...</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useUserStore } from '@/stores/lugand-api/userStore'
import AddressForm from '@/components/AddressForm.vue'

export default defineComponent({
  components: {
    AddressForm
  },
  setup() {
    const userStore = useUserStore()
    const user = userStore.user

    const updatedNickName = ref(user?.nick_name || '') // Nickname editável
    const avatarFile = ref<File | null>(null) // Novo arquivo de avatar
    const avatarURL = ref(user?.avatar_image || '') // URL para pré-visualização do avatar
    const updatedFirstName = ref(user?.first_name || '')
    const updatedLastName = ref(user?.last_name || '')
    const updatedCPF = ref(user?.CPF || '')
    const updatedDataNascimento = ref(user?.data_nascimento || '')
    const updatedPhones = ref(user?.phones || [{ label: '', number: '' }])
    const updatedAddresses = ref(
      user?.addresses || [
        {
          label: '',
          cep: '',
          street: '',
          number: '',
          complement: '',
          district: '',
          city: '',
          state: '',
          country: '',
          reference_point: ''
        }
      ]
    )

    // Adicionar novo endereço
    const addAddress = () => {
      updatedAddresses.value.push({
        label: '',
        cep: '',
        street: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
        country: '',
        reference_point: ''
      })
    }

    const updateAddress = (index: number, newAddress: any) => {
      updatedAddresses.value[index] = newAddress
    }

    // Lista de empresas editável
    const updatedCompanies = ref(
      user?.companies || [
        {
          company_name: '',
          cnpj: '',
          state_registration: '',
          legal_representative: ''
        }
      ]
    )

    // Adicionar nova empresa
    const addCompany = () => {
      updatedCompanies.value.push({
        company_name: '',
        cnpj: '',
        state_registration: '',
        legal_representative: ''
      })
    }

    // Remover empresa da lista
    const removeCompany = (index: number) => {
      updatedCompanies.value.splice(index, 1)
    }

    // Remover endereço da lista
    const removeAddress = (index: number) => {
      updatedAddresses.value.splice(index, 1)
    }

    // Função para atualizar o avatar
    const onAvatarChange = (e: Event) => {
      const file = (e.target as HTMLInputElement).files?.[0]
      if (file) {
        avatarFile.value = file
        avatarURL.value = URL.createObjectURL(file) // Previsão da imagem
      }
    }

    // Adicionar novo telefone
    const addPhone = () => {
      updatedPhones.value.push({ label: '', number: '' })
    }

    // Remover telefone da lista
    const removePhone = (index: number) => {
      updatedPhones.value.splice(index, 1)
    }

    // Função para atualizar as informações do usuário (nick_name e avatar)
    const updateUserInfo = async () => {
      if (user) {
        let avatarDownloadURL = avatarURL.value || ''
        // Se o usuário fez upload de uma nova imagem
        if (avatarFile.value) {
          avatarDownloadURL = (await userStore.uploadAvatar(avatarFile.value)) ?? '' // Upload do avatar e URL
        }

        await userStore.setUser({
          nick_name: updatedNickName.value, // Atualiza o nickname no Firestore
          avatar_image: avatarDownloadURL, // Atualiza o avatar no Firestore
          first_name: updatedFirstName.value,
          last_name: updatedLastName.value,
          CPF: updatedCPF.value,
          data_nascimento: updatedDataNascimento.value,
          phones: updatedPhones.value,
          addresses: updatedAddresses.value,
          companies: updatedCompanies.value
        })
        alert('Profile updated successfully!')
      }
    }

    return {
      user,
      updatedNickName,
      avatarURL,
      avatarFile,
      onAvatarChange,
      updatedFirstName,
      updatedLastName,
      updatedCPF,
      updatedDataNascimento,
      updatedPhones,
      addPhone,
      removePhone,
      updateAddress,
      updatedAddresses,
      addAddress,
      removeAddress,
      updatedCompanies,
      addCompany,
      removeCompany,
      updateUserInfo
    }
  }
})
</script>

<style scoped>
.add-button {
  background-color: var(--color-border);
  border-radius: 30px;
}

.remove-button {
  background-color: var(--color-danger);
  border-radius: 30px;
  margin-top: 10px;
}

#update-info-button {
  background-color: green;
  width: 100%;
}

.user-account-section {
  background-color: var(--color-shadow-border);
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.user-account {
  max-width: 600px;
  margin: 20px auto;
}

.user-info {
  background-color: var(--color-background);
  margin-bottom: 20px;
  border: 1px solid var(--color-text);
  border-radius: 10px;
  padding: 10px;
}

.user-info label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.user-info input {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.avatar-preview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 10px;
}

button {
  background-color: #42b883;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
