<template>
  <div class="cart-item">
    <ProductCard :product="item.product" />
    <div class="quantity">Quantity: {{ item.quantity }}</div>
    <div class="total-price">
      Total: {{ calculateTotal(Number(item.product.price.valor_a_vista), item.quantity) }}
    </div>
    <button @click="confirmRemoveItem">Remove Item</button>
  </div>

  <!-- Modal genérica para confirmação da exclusão -->
  <GenericModal :isVisible="showConfirmModal" @close="closeModal">
    <h2>Confirm Item Removal</h2>
    <p>Are you sure you want to remove this item from the cart?</p>
    <button @click="deleteItem">Confirm</button>
    <button @click="closeModal">Cancel</button>
  </GenericModal>
</template>

<script lang="ts">
import { defineComponent, type PropType, ref, onMounted } from 'vue'
import type { FullCart, FullCartItem } from '@/types/cart'
import ProductCard from '@/components/ProductCard.vue'
import { useRoute } from 'vue-router'
import { useCartStore } from '@/stores/lugand-api/cartStore'
import GenericModal from '@/components/GenericModal.vue'

export default defineComponent({
  name: 'CartItem',
  components: {
    ProductCard,
    GenericModal
  },
  props: {
    item: {
      type: Object as PropType<FullCartItem>,
      required: true
    },
    cartId: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  methods: {
    calculateTotal(price: number, quantity: number): string {
      return `R$ ${(price * quantity).toFixed(2)}`
    }
  },

  setup(props) {
    const cartStore = useCartStore()
    const route = useRoute()
    const cart = ref<FullCart | null>(null)
    const showConfirmModal = ref(false)

    const fetchCartDetails = async () => {
      const cartId = route.params.id as string
      cart.value = await cartStore.fetchCartById(cartId)
    }

    const confirmRemoveItem = () => {
      showConfirmModal.value = true // Mostra o modal de confirmação
    }

    const deleteItem = async () => {
      if (props.cartId) {
        try {
          await cartStore.removeItemFromCart(props.cartId, props.index!) // Garante que o `cartId` e o `index` não sejam `undefined`
          showConfirmModal.value = false // Fecha o modal após remover o item
        } catch (error) {
          console.error('Erro ao remover produto do carrinho:', error)
        }
      } else {
        console.error('Cart ID is missing!') // Loga um erro se `cartId` não estiver definido
      }
    }

    const closeModal = () => {
      showConfirmModal.value = false // Fecha o modal sem remover o item
    }

    onMounted(() => {
      fetchCartDetails()
    })

    return {
      cart,
      confirmRemoveItem,
      deleteItem,
      closeModal,
      showConfirmModal
    }
  }
})
</script>

<style scoped>
.cart-item {
  border: 1px solid var(--color-border);
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}

.quantity {
  font-size: 1.1em;
}

.total-price {
  margin-top: 5px;
  font-weight: bold;
}
</style>
