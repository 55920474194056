<template>
  <div class="login-container">
    <div class="login-banner">
      <div class="box-content">
        <div>
          <img src="@/assets/images/cute-robot.webp" alt="robo" />
        </div>
        <div>
          <div class="texto">
            <h3>Compre materiais para seus projetos da forma correta</h3>
            <p>
              Veja nossos produtos e estques, estamos aqui para suprir suas necessidades com prazos
              de entrega especiais
            </p>
          </div>
          <div class="texto">
            <h3>Sistemas embarcados e prontos para soluções específicas</h3>
            <p>
              Trabalhamos com consultoria de sistemas e produzimos o sistema ideal para sua
              aplicação
            </p>
          </div>
          <div class="texto">
            <h3>Acompanhe as novidades e inovações tecnologicas</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="login-box">
      <LogoAnimation />
      <br />
      <h2>Login</h2>
      <form @submit.prevent="handleLogin">
        <input class="input" v-model="email" type="email" placeholder="Email" required />
        <input class="input" v-model="password" type="password" placeholder="Password" required />
        <button id="login-button" type="submit">Login</button>
      </form>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      <div id="discrete-section">
        <button @click="handleResetPassword" class="discrete-button">Forgot password</button>
        <button @click="navigateToRegister" class="discrete-button">Register</button>
      </div>
      <p>or</p>
      <button @click="handleGoogleLogin" id="google-login-button">
        <IconGoogle />
        <div id="text-btn-google">Sign in with Google</div>
      </button>
    </div>
  </div>
  <AlertContainer />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { login, loginWithGoogle, resetPassword } from '@/services/lugand-api-services/authService'
import { useAlertService } from '@/services/lugand-api-services/alertService'
import LogoAnimation from '@/components/animations/LogoAnimation.vue'
import IconGoogle from '@/components/icons/IconGoogle.vue'
import AlertContainer from '@/components/AlertContainer.vue'

const email = ref('')
const password = ref('')
const errorMessage = ref('')
const router = useRouter()
const { addAlert } = useAlertService()

const showAlert = (
  title: string,
  message: string,
  type: 'default' | 'success' | 'error' | 'warning'
) => {
  addAlert(title, message, type)
}

const handleLogin = async () => {
  try {
    await login(email.value, password.value)
    showAlert('Success', 'Login successful', 'success')
    setTimeout(() => router.push('/'), 2000)
  } catch (error: any) {
    errorMessage.value = 'Login failed, try again'
    showAlert('Error', 'Login failed, try again', 'error')
    console.log('Login error:', error.message)
  }
}

const handleResetPassword = async () => {
  try {
    await resetPassword(email.value)
    showAlert('Success', 'Password reset email sent', 'success')
  } catch (error: any) {
    errorMessage.value = 'Password reset failed, provide a valid email and try again'
    showAlert('Error', 'Password reset failed, provide a valid email and try again', 'error')
    console.log('Password reset error:', error.message)
  }
}

const handleGoogleLogin = async () => {
  try {
    const { user, isNewUser } = await loginWithGoogle()
    console.log('Logged User: ', user.displayName, ' - ', user.email)
    // console.log('User: ', user)
    if (isNewUser) {
      showAlert('Success', 'Google sign-up successful', 'success')
    } else {
      showAlert('Success', 'Google login successful', 'success')
    }
    setTimeout(() => router.push('/'), 2000)
  } catch (error: any) {
    errorMessage.value = 'Google login failed, try again'
    showAlert('Error', 'Google login failed, try again', 'error')
    console.log('Google login error:', error.message)
  }
}

const navigateToRegister = () => {
  router.push('/register')
}
</script>

<style scoped>
.login-banner {
  border: 1px solid var(--color-border);
  border-radius: 8px;
  height: 438px;
  width: 652px;
  margin-right: 10px;
  padding: 10px;
  background-image: url('@/assets/images/background-banner-login.jpg');
  background-size: cover;
  background-position: center;
}

.box-content {
  display: flex;
  gap: 10px;
}

.texto {
  display: block;
  background-color: rgba(159, 206, 207, 0.13);
  padding: 10px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin-bottom: 8px;
  border-radius: 8px;
  color: aliceblue;
}

input {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

button {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  width: 100%;
  color: white;
}

#login-button {
  background-color: green;
}

#discrete-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20%;
}

.discrete-button {
  border: none;
  padding: 3px;
  margin: 0%;
  width: auto;
  color: var(--color-border);
}

#google-login-button {
  background-color: aliceblue;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  max-width: 100%;
}

.login-box {
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid var(--color-border);
  box-shadow: 2px 2px 8px 2px var(--color-shadow-border);
  text-align: center;
  background-image: url('@/assets/images/background-login.jpg');
  background-size: cover;
  background-position: center;
}

.login-box h2 {
  background-color: var(--color-border);
  color: aliceblue;
  margin-bottom: 1rem;
}

.login-box form {
  display: flex;
  flex-direction: column;
}

.error {
  color: red;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 980px) {
  .login-banner {
    display: none;
  }

  .login-container {
    height: 80vh;
  }
}
</style>
