<template>
  <div class="address-form">
    <h3>{{ address.label }}</h3>
    <div>
      <label for="add_name_label">Label</label><br />
      <input v-model="address.label" placeholder="Label" />
    </div>
    <div>
      <label for="cep">CEP</label><br />
      <input v-model="cep" @blur="fetchAddressByCep" placeholder="Enter CEP" />
    </div>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <div>
        <label for="street">Street</label><br />
        <input v-model="address.street" placeholder="Street" />
      </div>
      <div>
        <label for="number">Number</label><br />
        <input v-model="address.number" placeholder="Number" />
      </div>
      <div>
        <label for="complement">Complement</label><br />
        <input v-model="address.complement" placeholder="Complement" />
      </div>
      <div>
        <label for="district">District</label><br />
        <input v-model="address.district" placeholder="District" />
      </div>
      <div>
        <label for="city">City</label><br />
        <input v-model="address.city" placeholder="City" />
      </div>
      <div>
        <label for="state">State</label><br />
        <input v-model="address.state" placeholder="State" />
      </div>
      <div>
        <label for="country">Country</label><br />
        <input v-model="address.country" placeholder="Country" />
      </div>
      <div>
        <label for="reference_point">Reference Point</label><br />
        <input v-model="address.reference_point" placeholder="Reference Point" />
      </div>
      <button @click="saveAddress">Save Address</button>
    </div>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import axios from 'axios'

export default {
  props: {
    initialAddress: {
      type: Object,
      default: () => ({
        label: '',
        cep: '',
        street: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
        country: 'Brasil',
        reference_point: ''
      })
    }
  },
  setup(props, { emit }) {
    const cep = ref(props.initialAddress.cep)
    const address = ref({ ...props.initialAddress })
    const loading = ref(false)

    const fetchAddressByCep = async () => {
      if (!cep.value || cep.value.length < 8) return
      loading.value = true
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep.value}/json/`)
        if (!response.data.erro) {
          address.value.street = response.data.logradouro
          address.value.district = response.data.bairro
          address.value.city = response.data.localidade
          address.value.state = response.data.uf
        } else {
          alert('CEP not found')
        }
      } catch (error) {
        console.error('Error fetching address:', error)
      } finally {
        loading.value = false
      }
    }

    const saveAddress = () => {
      emit('save', { ...address.value, cep: cep.value })
    }

    return {
      cep,
      address,
      loading,
      fetchAddressByCep,
      saveAddress
    }
  }
}
</script>

<style scoped>
.address-form {
  margin: 1rem 0;
}
</style>
