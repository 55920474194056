<template>
  <div @click="goToApiProductDetails" class="product border">
    <div v-if="isDiscountValid" class="discount-banner">Discount</div>
    <img v-if="firstImage" :src="firstImage" alt="Product" class="product-image" />
    <div id="cart-add-btns-container">
      <!-- Wishlist -->
      <button
        :class="isInWishlist ? 'wishlist-btn active' : 'wishlist-btn'"
        @click.stop="toggleWishlist"
      >
        <i class="fa fa-heart"></i>
      </button>
      <!-- Cart -->
      <button class="cart-btn" @click.stop="handleAddToCart">
        <IconCart />
      </button>
    </div>
    <div class="product-info">
      <p>{{ product.name }}</p>
      <p class="text-valor-a-vista">Original Price: {{ product.price.valor_original || '-' }}</p>
      <p>Price à Vista: {{ product.price.valor_a_vista || '-' }}</p>
      <p>
        Parcelado:
        {{
          product.price.parcelado
            ? `${product.price.parcelado.valor} em
        ${product.price.parcelado.numero_parcelas}x`
            : '-'
        }}
      </p>
    </div>
    <p v-if="isDiscountValid" class="discount-timer">acaba em: {{ timeRemaining }}</p>
    <p v-else class="discount-timer"></p>
  </div>

  <!-- Modal para adicionar ao carrinho -->
  <CartModal
    v-if="showModal"
    :productId="product.id"
    :quantity="1"
    :showModal="showModal"
    :onClose="closeModal"
  />
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import type { Product } from '@/types/product'
import { useWishlistStore } from '@/stores/lugand-api/wishlistStore'
import { useCartStore } from '@/stores/lugand-api/cartStore'
import { useUserStore } from '@/stores/lugand-api/userStore'
import IconCart from '@/components/icons/IconCart.vue'
import CartModal from '@/components/CartModal.vue'

export default defineComponent({
  name: 'ProductCard',
  props: {
    product: {
      type: Object as () => Product,
      required: true
    }
  },
  components: {
    IconCart,
    CartModal
  },
  setup(props) {
    const router = useRouter()
    const timeRemaining = ref('')
    const isDiscountValid = ref(false)
    const showModal = ref(false)
    const wishlistStore = useWishlistStore()
    const isInWishlist = computed(() => wishlistStore.isInWishlist(props.product.id))
    const cartStore = useCartStore()
    const userStore = useUserStore()

    const handleAddToCart = async () => {
      if (userStore.user) {
        await cartStore.fetchCarts() // Busca os carrinhos
        showModal.value = true // Abre a modal de adição ao carrinho
      } else {
        router.push({ name: 'login' })
      }
    }

    const closeModal = () => {
      showModal.value = false
    }

    // Alternar produto na wishlist
    const toggleWishlist = () => {
      if (userStore.user) {
        if (isInWishlist.value) {
          wishlistStore.removeFromWishlist(props.product.id)
        } else {
          wishlistStore.addToWishlist(props.product.id)
        }
      } else {
        // Usuário não está logado, redireciona para a página de login
        router.push({ name: 'login' })
      }
    }

    const goToApiProductDetails = () => {
      router.push({ name: 'product-details', params: { id: props.product.id } })
    }

    const firstImage = computed(() => {
      return props.product.images.length > 0 ? props.product.images[0].image : null
    })

    const calculateTimeRemaining = () => {
      if (!props.product.discount) return

      const endTime = new Date(props.product.discount.data_termino).getTime()
      const now = new Date().getTime()
      const timeDiff = endTime - now

      if (timeDiff <= 0) {
        isDiscountValid.value = false
        return
      }

      isDiscountValid.value = true

      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000)

      timeRemaining.value = `${days} Dias e ${hours}:${minutes}:${seconds}`
    }

    let interval: any

    onMounted(() => {
      if (props.product.discount) {
        calculateTimeRemaining()
        interval = setInterval(calculateTimeRemaining, 1000)
      }
    })

    onUnmounted(() => {
      clearInterval(interval)
    })

    return {
      goToApiProductDetails,
      firstImage,
      timeRemaining,
      isDiscountValid,
      isInWishlist,
      toggleWishlist,
      handleAddToCart,
      showModal,
      closeModal
    }
  }
})
</script>

<style scoped>
#cart-add-btns-container {
  display: flex;
  justify-content: flex-end;
}

#cart-add-btns-container button {
  border: 1px solid var(--color-border);
  border-radius: 50%;
  margin-right: 5px;
  width: 35px;
  height: 35px;
}

.wishlist-btn {
  background-color: var(--color-shadow-border);
  background-color: var(--color-background);
  color: var();
}

.wishlist-btn.active {
  background-color: var(--color-shadow-border);
  color: red;
}

.cart-btn {
  background-color: var(--color-background);
  color: var(--color-text);
}

.text-valor-a-vista {
  text-decoration: line-through;
  color: grey;
}

.product {
  position: relative;
  transition: transform 0.2s;
}

.product:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.product-image {
  width: 100%;
  height: 200px;
  margin-bottom: 0px;
}

.product-info {
  padding: 10px;
  height: 150px;
}

.discount-banner {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--color-border);
  color: var(--color-background);
  padding: 5px;
  font-weight: bold;
  border-bottom-left-radius: 25%;
}

.discount-timer {
  background-color: var(--color-border);
  color: var(--color-background);
  height: 25px;
  text-align: center;
}
</style>
