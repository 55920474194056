<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <!-- Slot para conteúdo dinâmico -->
      <slot></slot>
      <button class="close-button" @click="closeModal">x</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GenericModal',
  props: {
    isVisible: {
      type: Boolean,
      required: true // A modal será controlada externamente (true ou false)
    }
  },
  emits: ['close'], // Emitir evento quando a modal for fechada
  methods: {
    closeModal() {
      this.$emit('close') // Emitir o evento 'close' para que o componente pai feche a modal
    }
  }
})
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fundo escuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: var(--color-background);
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
}
</style>
