import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { getAllBanners, getTableData, type Banner } from '@/services/development/productService'
import {
  getProductById,
  getProductsByIds,
  getAllProducts,
  getAllProductCategories
} from '@/services/lugand-api-services/productService'
import type { Product, Category } from '@/types/product'

export const useProductStore = defineStore('product', () => {
  // State
  const products = ref<Product[]>([])
  const product = ref<Product | null>(null)
  const categories = ref<Category[]>([])
  const banners = ref<Banner[]>([])
  const tableData = ref<Record<string, any>[]>([])
  const loading = ref<boolean>(false)

  // Getters
  const getProductByIdGetter = (id: number) => {
    return computed(() => products.value.find((product) => product.id === id))
  }

  // Actions
  const fetchProducts = async () => {
    try {
      loading.value = true
      products.value = await getAllProducts()
    } catch (error) {
      console.error('Error fetching products:', error)
    } finally {
      loading.value = false
    }
  }

  const fetchProductById = async (id: number) => {
    try {
      loading.value = true
      product.value = await getProductById(id)
    } catch (error) {
      console.error('Error fetching product by ID:', error)
    } finally {
      loading.value = false
    }
  }

  // Função para carregar produtos favoritos específicos por IDs
  const loadFavoriteProducts = async (ids: number[]) => {
    const favoriteProducts = await getProductsByIds(ids)
    products.value = favoriteProducts
  }

  const fetchProductCategories = async () => {
    try {
      loading.value = true
      categories.value = await getAllProductCategories()
    } catch (error) {
      console.error('Error fetching product categories:', error)
    } finally {
      loading.value = false
    }
  }

  const fetchBanners = async () => {
    try {
      loading.value = true
      banners.value = await getAllBanners()
    } catch (error) {
      console.error('Error fetching banners:', error)
    } finally {
      loading.value = false
    }
  }

  const fetchTableData = async () => {
    try {
      loading.value = true
      tableData.value = await getTableData()
    } catch (error) {
      console.error('Error fetching table data:', error)
    } finally {
      loading.value = false
    }
  }

  return {
    products,
    product,
    categories,
    banners,
    tableData,
    loading,
    getProductByIdGetter,
    loadFavoriteProducts,
    fetchProducts,
    fetchProductById,
    fetchProductCategories,
    fetchBanners,
    fetchTableData
  }
})
