<template>
  <div class="fancy-title">
    <h1>My Favorites</h1>
    <hr />
  </div>
  <div class="container">
    <div v-if="favoriteProducts.length > 0" class="items">
      <div v-for="product in favoriteProducts" :key="product.id" class="card">
        <ProductCard :product="product" />
      </div>
    </div>
    <p v-else>No favorites yet.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watch } from 'vue'
import { useWishlistStore } from '@/stores/lugand-api/wishlistStore'
import { useProductStore } from '@/stores/lugand-api/productStore'
import ProductCard from '@/components/ProductCard.vue'

export default defineComponent({
  components: {
    ProductCard
  },
  setup() {
    const wishlistStore = useWishlistStore()
    const productStore = useProductStore()

    // Carregar produtos favoritos ao montar a view
    onMounted(async () => {
      if (wishlistStore.wishlist.length) {
        await productStore.loadFavoriteProducts(wishlistStore.wishlist)
      }
    })

    // Recarregar produtos sempre que `wishlist` for atualizada
    watch(wishlistStore.wishlist, async (newWishlist) => {
      if (newWishlist.length) {
        await productStore.loadFavoriteProducts(newWishlist)
      }
    })

    // Computado para obter os produtos favoritos
    const favoriteProducts = computed(() => productStore.products)

    return {
      favoriteProducts
    }
  }
})
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: normal;
  padding: 20px;
  width: 100%;
}

.items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  width: 100%;
}

.card {
  flex: 1 1 225px;
  max-width: 225px;
  box-sizing: border-box;
}

@media (max-width: 420px) {
  .card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
</style>
