<!-- src/views/CartView.vue -->
<template>
  <div class="fancy-title">
    <h1>My Carts</h1>
    <hr />
  </div>
  <div class="cart-view">
    <div class="cart-container">
      <CartList />
      <router-view />
      <!-- Render child route components here -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CartList from '@/components/CartList.vue'

export default defineComponent({
  name: 'CartView',
  components: {
    CartList
  }
})
</script>

<style scoped>
.cart-view {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Estilo para telas maiores */
@media (min-width: 768px) {
  .cart-view {
    flex-direction: row;
  }

  .cart-container {
    flex-direction: row;
  }
}
</style>
