import axios from 'axios'
import type { Product, Category, Image, Price } from '@/types/product'

const BASE_URL = 'http://localhost:8000/api/product'
const IMAGES_URL = `${BASE_URL}/product-images`

export const getAllProducts = async (): Promise<Product[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/products`)
    console.log('Fetched products:', response.data)
    return response.data || []
  } catch (error) {
    console.error('Error fetching products:', error)
    return []
  }
}

export const getProductById = async (id: number): Promise<Product | null> => {
  try {
    const response = await axios.get(`${BASE_URL}/products/${id}`)
    console.log('Fetched product by ID:', response.data)
    return response.data || null
  } catch (error) {
    console.error('Error fetching product by ID:', error)
    return null
  }
}

export const getProductImagesById = async (productId: number) => {
  try {
    const response = await axios.get(`${IMAGES_URL}/?product=${productId}`)
    return response.data.filter((image: any) => image.product === productId)
  } catch (error) {
    console.error('Error fetching product images by ID:', error)
    throw error
  }
}

// pega produtos a partir de uma lista de ids (drf product view actions)
export const getProductsByIds = async (ids: number[]): Promise<Product[]> => {
  try {
    const response = await axios.post(`${BASE_URL}/products/by_ids/`, { ids })
    console.log('Fetched products by IDs:', response.data)
    return response.data || []
  } catch (error) {
    console.error('Error fetching products by IDs:', error)
    return []
  }
}

export const getAllProductImages = async (): Promise<Image[]> => {
  try {
    const response = await axios.get(`${IMAGES_URL}/`)
    console.log('Fetched product images:', response.data)
    const images: Image[] = response.data.flatMap((product: Product) => product.images)
    return images
  } catch (error) {
    console.error('Error fetching product images:', error)
    return []
  }
}

export const getAllPrices = async (): Promise<Price[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/prices`)
    console.log('Fetched prices:', response.data)
    return response.data || []
  } catch (error) {
    console.error('Error fetching prices:', error)
    return []
  }
}

export const getPricesByProductId = async (productId: number): Promise<Price | null> => {
  try {
    const response = await axios.get(`${BASE_URL}/prices/?product=${productId}`)
    console.log('Fetched prices by product ID:', response.data)
    return response.data.length > 0 ? response.data[0] : null
  } catch (error) {
    console.error('Error fetching prices by product ID:', error)
    return null
  }
}

export const getAllProductCategories = async (): Promise<Category[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/products`)
    console.log('Fetched product categories:', response.data)
    const categories: Category[] = response.data.flatMap((product: Product) => product.categories)

    // Remove duplicate categories
    const uniqueCategories = Array.from(new Set(categories.map((cat: Category) => cat.id)))
      .map((id) => categories.find((cat) => cat.id === id))
      .filter((cat): cat is Category => cat !== undefined) // Filtra valores undefined

    return uniqueCategories
  } catch (error) {
    console.error('Error fetching product categories:', error)
    return []
  }
}

export type { Product, Category, Image, Price }
