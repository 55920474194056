import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/user_auth_components/UserLogin.vue'
import Register from '@/components/user_auth_components/UserRegister.vue'
import { auth } from '@/services/lugand-api-services/authService'
import HomeView from '../views/HomeView.vue'
import ProductDetailsView from '../views/ProductDetailsView.vue'
import SearchResultsView from '../views/SearchResultsView.vue'
import CartView from '../views/CartView.vue'
import CartDetails from '../components/CartDetails.vue'
import AboutUsView from '@/views/AboutUsView.vue'
import UserAccountView from '@/views/UserAccountView.vue'
import FavoritesView from '@/views/FavoritesView.vue'
import CheckoutView from '@/views/CheckoutView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/product/:id',
      name: 'product-details',
      component: ProductDetailsView
    },
    {
      path: '/search',
      name: 'search-results',
      component: SearchResultsView
    },
    {
      path: '/about',
      name: 'about-us',
      component: AboutUsView
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/cart',
      name: 'cart-view',
      component: CartView,
      children: [
        {
          path: ':id',
          name: 'cart-details',
          component: CartDetails
        }
      ],
      meta: { requiresAuth: true }
    },
    {
      path: '/favorites',
      name: 'favorites',
      component: FavoritesView,
      meta: { requiresAuth: true }
    },
    {
      path: '/account',
      name: 'user-account',
      component: UserAccountView, // Criar o componente
      meta: { requiresAuth: true } // Apenas usuários logados podem acessar
    },
    {
      path: '/checkout',
      name: 'checkout-view',
      component: CheckoutView,
      meta: { requiresAuth: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const isAuthenticated = auth.currentUser

  if (requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    next()
  }
})

export default router
