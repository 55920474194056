// src/stores/checkoutStore.ts
import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { CheckoutItem } from '@/types/checkout'

export const useCheckoutStore = defineStore('checkout', () => {
  const items = ref<CheckoutItem[]>([])

  const setItems = (newItems: CheckoutItem[]) => {
    items.value = newItems
  }

  return { items, setItems }
})
