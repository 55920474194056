<template>
  <teleport to="body">
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <h2>Add to Cart</h2>
        <div>
          <h3>Create a new cart</h3>
          <input type="text" v-model="newCartName" placeholder="New cart name" />
        </div>
        <br />
        <div>
          <h3>Select existing cart(s)</h3>
          <hr />
          <div v-if="carts.length > 0">
            <div class="scroller">
              <div
                v-for="cart in carts"
                :key="cart.id"
                :class="['cart-item', selectedCarts.includes(cart.id) ? 'selected' : '']"
                @click="toggleCartSelection(cart.id)"
              >
                <input
                  type="checkbox"
                  :value="cart.id"
                  v-model="selectedCarts"
                  id="cart-{{ cart.id }}"
                  class="checkbox"
                />
                <label :for="'cart-' + cart.id" class="cart-label">{{ cart.name }}</label>
              </div>
            </div>
          </div>
          <div v-else>
            <p>You have no carts. Create one below:</p>
          </div>
          <hr />
        </div>
        <div class="modal-actions">
          <button @click="confirmAddition">Add to Cart</button>
          <button @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useCartStore } from '@/stores/lugand-api/cartStore'

export default defineComponent({
  props: {
    productId: {
      type: Number,
      required: true
    },
    quantity: {
      type: Number,
      required: true
    },
    showModal: {
      type: Boolean,
      required: true
    },
    onClose: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const cartStore = useCartStore()
    const carts = ref(cartStore.carts) // Lista de carrinhos existentes
    const selectedCarts = ref<string[]>([]) // Carrinhos selecionados pelo usuário
    const newCartName = ref<string>('') // Nome do novo carrinho

    const toggleCartSelection = (cartId: string) => {
      if (selectedCarts.value.includes(cartId)) {
        // Se o carrinho já está selecionado, remove-o da lista
        selectedCarts.value = selectedCarts.value.filter((id) => id !== cartId)
      } else {
        // Caso contrário, adiciona o carrinho à lista
        selectedCarts.value.push(cartId)
      }
    }

    // Função para fechar o modal
    const closeModal = () => {
      props.onClose()
    }

    // Função para buscar os carrinhos toda vez que o modal for aberto
    const fetchCarts = async () => {
      await cartStore.fetchCarts() // Garante que os carrinhos sejam buscados do store
      carts.value = cartStore.carts // Atualiza a lista de carrinhos reativamente
    }

    // Função para confirmar a adição aos carrinhos selecionados
    const confirmAddition = async () => {
      try {
        for (const cartId of selectedCarts.value) {
          await cartStore.addItemToCart(cartId, props.productId, props.quantity)
        }
        if (newCartName.value.trim()) {
          const newCartId = await cartStore.createCart(newCartName.value)
          await cartStore.addItemToCart(newCartId, props.productId, props.quantity)
        }
        closeModal() // Fecha o modal após a operação
      } catch (error) {
        console.error('Erro ao adicionar produto ao carrinho:', error)
      }
    }

    // Monitora a abertura do modal e busca os carrinhos
    watch(
      () => props.showModal,
      (newVal) => {
        if (newVal) {
          fetchCarts() // Quando o modal é aberto, carrega os carrinhos
        }
      }
    )

    return {
      carts,
      selectedCarts,
      newCartName,
      closeModal,
      confirmAddition,
      toggleCartSelection
    }
  }
})
</script>
<style scoped>
.scroller {
  max-height: 20vh;
}

.cart-item {
  background-color: var(--color-shadow);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--color-shadow);
  cursor: pointer;
}

.cart-item:hover {
  background-color: var(--color-shadow-border);
}

.cart-item.selected {
  background-color: var(--color-shadow-border);
  border-color: var(--color-shadow-border);
}

.checkbox {
  width: 20px;
  height: 20px;
  accent-color: var(--color-border);
  cursor: pointer;
}

.cart-label {
  font-size: 16px;
  color: var(--color-text);
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modal-content {
  background: var(--color-background);
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 100%;
  z-index: 4;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
