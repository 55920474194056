// import axios from 'axios'

// // API Base URL
// const BASE_URL = 'http://localhost:3000'

// COMPONENTS:
// Mock for component: BannerCard.vue
export interface Banner {
  image: string
  title: string
  description: string
}
const banners: Banner[] = [
  {
    image: 'https://via.placeholder.com/800x400',
    title: 'Banner 1',
    description: '1 This is the first banner description.'
  },
  {
    image: 'https://via.placeholder.com/800x400',
    title: 'Banner 2',
    description: '1 This is the first banner description.'
  },
  {
    image: 'https://via.placeholder.com/800x400',
    title: 'Banner 3',
    description: '1 This is the first banner description.'
  },
  {
    image: 'https://via.placeholder.com/800x400',
    title: 'Banner 4',
    description: '1 This is the first banner description.'
  },
  {
    image: 'https://via.placeholder.com/800x400',
    title: 'Banner 5',
    description: '1 This is the first banner description.'
  }
]
export const getAllBanners = (): Promise<Banner[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      console.log('getAllBanners: ', banners)
      resolve(banners)
    }, 500) // Simulate a network delay
  })
}

// Mock for component: GenericTable.vue
const tableData = [
  { Column1: 'value 1', Column2: 'value 2' },
  { Column1: 'value 1', Column2: 'value 2' },
  { Column1: 'value 1', Column2: 'value 2' },
  { Column1: 'value 1', Column2: 'value 2' }
]
export const getTableData = (): Promise<Record<string, any>[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      console.log('getTableData:', tableData)
      resolve(tableData)
    }, 500) // Simulate a network delay
  })
}
