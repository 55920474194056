<template>
  <div>
    <div v-if="categories.length">
      <div v-for="(category, index) in categories" :key="index">
        <h3>{{ category.name }}</h3>
        <ProductCarousel :cards="category.products">
          <template #default="{ card }">
            <ProductCard :product="card" />
          </template>
        </ProductCarousel>
        <br />
      </div>
    </div>
    <div v-else>
      <p>No categories available.</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import ProductCarousel from './ProductCarousel.vue'
import ProductCard from './ProductCard.vue'
import { getAllProducts, type Product } from '../services/lugand-api-services/productService'

interface Category {
  id: number
  name: string
  products: Product[]
}

const categories = ref<Category[]>([])

const fetchCategoriesAndProducts = async () => {
  try {
    const products = await getAllProducts()

    console.log('Fetched products:', products)

    if (!Array.isArray(products)) {
      throw new Error('Expected array for products')
    }

    const categoryMap: Record<number, Category> = {}

    products.forEach((product) => {
      product.categories.forEach((category) => {
        if (!categoryMap[category.id]) {
          categoryMap[category.id] = {
            id: category.id,
            name: category.name,
            products: []
          }
        }
        categoryMap[category.id].products.push(product)
      })
    })

    categories.value = Object.values(categoryMap)
    console.log('Mapped categories with products:', categories.value)
  } catch (error) {
    console.error('Error in fetchCategoriesAndProducts:', error)
  }
}

onMounted(() => {
  fetchCategoriesAndProducts()
})
</script>
