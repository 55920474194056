import { defineStore } from 'pinia'
import { ref, onMounted, watch } from 'vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove
} from 'firebase/firestore'

export const useWishlistStore = defineStore('wishlist', () => {
  const wishlist = ref<number[]>(JSON.parse(localStorage.getItem('wishlist') ?? '[]'))
  const auth = getAuth()
  const db = getFirestore()
  const userId = ref<string | null>(null)

  // Sincronizar a `wishlist` com `localStorage`
  watch(
    wishlist,
    (newWishlist) => {
      localStorage.setItem('wishlist', JSON.stringify(newWishlist))
    },
    { deep: true }
  )

  // Função para carregar a wishlist do Firestore
  const loadWishlist = async () => {
    if (!userId.value) return

    const wishlistRef = doc(db, 'wishlist', userId.value)
    const wishlistSnap = await getDoc(wishlistRef)

    if (wishlistSnap.exists()) {
      wishlist.value = wishlistSnap.data().products || []
    } else {
      await setDoc(wishlistRef, { products: [] })
      wishlist.value = []
    }
  }

  // Funções de adicionar e remover produto na wishlist
  const addToWishlist = async (productId: number) => {
    if (!userId.value) return

    const wishlistRef = doc(db, 'wishlist', userId.value)
    await updateDoc(wishlistRef, {
      products: arrayUnion(productId)
    })
    wishlist.value.push(productId)
  }

  const removeFromWishlist = async (productId: number) => {
    if (!userId.value) return

    const wishlistRef = doc(db, 'wishlist', userId.value)
    await updateDoc(wishlistRef, {
      products: arrayRemove(productId)
    })
    wishlist.value = wishlist.value.filter((id) => id !== productId)
  }

  // Função para verificar se um produto está na wishlist
  const isInWishlist = (productId: number) => {
    return wishlist.value.includes(productId) // Compara como número
  }

  // Sincronizar com autenticação
  onMounted(() => {
    onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        userId.value = firebaseUser.uid
        loadWishlist()
      } else {
        userId.value = null
        wishlist.value = []
        localStorage.removeItem('wishlist')
      }
    })
  })

  return {
    wishlist,
    loadWishlist,
    addToWishlist,
    isInWishlist,
    removeFromWishlist
  }
})
