import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  arrayUnion,
  arrayRemove
} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import axios from 'axios'
import { type Product } from '@/types/product'
import type { CartItem, FullCart } from '@/types/cart'
import { createSafeProduct } from '@/utils/productUtils'

// Inicializa Firestore e Auth
const db = getFirestore()
const auth = getAuth()

const BASE_URL = 'http://localhost:8000/api/product' // URL base para buscar produtos via API

// Função para buscar detalhes dos produtos via API pelo `product_id`
const fetchProducts = async (productIds: number[]): Promise<Product[]> => {
  const response = await axios.post<Product[]>(`${BASE_URL}/products/by_ids/`, { ids: productIds })
  return response.data
}

// Função para buscar todos os carrinhos do Firestore de um usuário logado
export const getAllCarts = async (): Promise<FullCart[]> => {
  const user = auth.currentUser
  if (!user) throw new Error('Usuário não está logado')

  const cartsRef = collection(db, 'carts')
  const q = query(cartsRef, where('user_id', '==', user.uid))
  const cartDocs = await getDocs(q)

  const productIds: number[] = []
  const carts: {
    id: string
    name: string
    items: CartItem[]
  }[] = []

  cartDocs.forEach((doc) => {
    const cartData = doc.data()
    const itemsArray: CartItem[] = cartData.items || []

    carts.push({
      id: doc.id,
      name: cartData.name || 'Sem nome',
      items: itemsArray.map((item) => ({
        product_id: item.product_id,
        quantity: item.quantity || 1
      }))
    })

    itemsArray.forEach((item) => productIds.push(item.product_id))
  })

  if (productIds.length === 0) {
    return carts.map((cart) => ({
      id: cart.id,
      name: cart.name,
      items: []
    }))
  }

  const products = await fetchProducts(productIds)

  return carts.map((cart) => ({
    id: cart.id,
    name: cart.name,
    items: cart.items.map((item: CartItem) => {
      const product = products.find((p) => p.id === item.product_id)
      return {
        product: product || createSafeProduct(item.product_id),
        quantity: item.quantity
      }
    })
  }))
}

// Função para buscar um carrinho específico por ID
export const getCartById = async (cartId: string): Promise<FullCart | null> => {
  const user = auth.currentUser
  if (!user) throw new Error('Usuário não está logado')

  const cartRef = doc(db, 'carts', cartId)
  const cartSnap = await getDoc(cartRef)

  if (!cartSnap.exists()) return null

  const cartData = cartSnap.data()
  const itemsArray: CartItem[] = cartData.items || []

  // Verifica se há produtos no carrinho
  if (itemsArray.length === 0) {
    return {
      id: cartSnap.id,
      name: cartData.name || 'Sem nome',
      items: [] // Retorna um carrinho vazio
    }
  }

  const productIds = itemsArray.map((item) => item.product_id)

  // Evita buscar produtos se não houver IDs
  const products = productIds.length > 0 ? await fetchProducts(productIds) : []

  // Converte os itens do carrinho em `FullCartItem[]`
  const fullCartItems = itemsArray.map((item: CartItem) => {
    const product = products.find((p) => p.id === item.product_id)

    return {
      product: product || createSafeProduct(item.product_id),
      quantity: item.quantity
    }
  })

  // Retorna o carrinho completo com os produtos mapeados
  return {
    id: cartSnap.id,
    name: cartData.name || 'Sem nome',
    items: fullCartItems
  }
}

// Função para criar um novo carrinho
export const createNewCart = async (cartName: string): Promise<string> => {
  const user = auth.currentUser
  if (!user) throw new Error('Usuário não está logado')

  const cartsRef = collection(db, 'carts')
  const newCart = await addDoc(cartsRef, {
    user_id: user.uid,
    name: cartName,
    items: [],
    created_at: new Date()
  })

  return newCart.id
}

// Função para adicionar um item ao carrinho
export const addToCart = async (
  cartId: string,
  productId: number,
  quantity: number
): Promise<void> => {
  const cartRef = doc(db, 'carts', cartId)
  await updateDoc(cartRef, {
    items: arrayUnion({
      product_id: productId,
      quantity: quantity
    })
  })
}

// Função para remover um item do carrinho
export const removeFromCart = async (cartId: string, productId: number): Promise<void> => {
  const cartRef = doc(db, 'carts', cartId)
  await updateDoc(cartRef, {
    items: arrayRemove({ product_id: productId })
  })
}

// Função para remover um item do carrinho usando o índice
export const removeItemByIndex = async (cartId: string, index: number): Promise<void> => {
  const cartRef = doc(db, 'carts', cartId)
  const cartSnap = await getDoc(cartRef)

  if (!cartSnap.exists()) {
    throw new Error('Carrinho não encontrado')
  }

  const cartData = cartSnap.data()
  const updatedItems = [...cartData.items] // Cria uma cópia do array de items

  if (index >= 0 && index < updatedItems.length) {
    updatedItems.splice(index, 1) // Remove o item na posição do índice
  } else {
    throw new Error('Índice inválido para remoção')
  }

  // Atualiza o documento no Firestore com o array atualizado
  await updateDoc(cartRef, {
    items: updatedItems
  })
}

export type { CartItem, FullCart }
